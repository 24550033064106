import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"
import './footer.css'
function Footer() {
    return (
        <div>
            {/* Footer Section Start*/}
            <div className="footer-section accent-bg">
                <div className="container">
                    <div className="row">
                        <div className="col lg-4 footer-ul-custom col-12 col-sm-4">
                            <div className="col footer-heading">
                                <span className="font-text fw-bold me-2">Scholar </span>
                                <span className="font-text">Present</span>
                            </div>
                            <ul className="font-text footer-sec-first">
                            <li>
                                Scholar Present aims to bring together communities
                                </li>
                                <li>
                                across the world in
                                order to inspire, guide and nurture children.  
                                We want to ensure this is achieved through modern technological 
                                mediums and set new standards in
                                </li>
                                <li>
                                proving a safe and nurturing platform to enable inspired connections.
                                </li>
                            </ul>
                            <div className="footer-share-section col-12 col-sm-12">
                                <span className="me-4 cursor-pointer">
                                    <a href="https://www.facebook.com/profile.php?id=61555926503880">
                                    <img src="image/facebook_icon.svg" />
                                    </a>
                                </span>
                                <span className="me-4 cursor-pointer">
                                   <a href="https://twitter.com/ScholarPresent">
                                    <img src="image/twitter_icon.svg " />
                                   </a>
                                </span>
                                <span className="me-4 cursor-pointer">
                                    <a href="https://www.instagram.com/scholar_present/">
                                    <img src="image/instagram_icon.svg" />
                                    </a>
                                </span>
                                <span className="me-4 cursor-pointer">
                                <a href="https://www.linkedin.com/company/101028877/admin/feed/posts/">
                                    <img src="image/linkedin_icon.svg" />
                                 </a>   
                                </span>
                                <span className="me-4 cursor-pointer">
                                <a href="https://www.tiktok.com/@scholar_present">
                                    <img src="image/tiktok-logo.svg" className="logo-background"/>
                                 </a>   
                                </span>
                            </div>
                        </div>
                        <div className="col lg-4 footer-ul-custom col-sm-4 col-12">
                            <div className="col footer-heading">
                                <span className="font-text fw-bold me-2">Quick Links </span>
                            </div>
                            <ul className="font-text footer-sec-first">
                                <li>
                                    <Link to="/"> Home</Link>
                                </li>
                                <li>
                                    <Link to="/aboutus"> About Us</Link>
                                </li>
                                <li>
                                    <Link to="/app"> Download App</Link>
                                </li>
                                <li>
                                    <Link to="/faq"> FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/contactus"> Contact Us</Link>
                                </li>
                                <li>
                                    <a href="../../privacypolicy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="https://iconnect99-public.s3.eu-central-1.amazonaws.com/Scholar+Present+Terms+of+Service.pdf" target="_blank">Terms & Conditions</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col lg-4 footer-ul-custom">
                            <div className="col footer-heading">
                                <span className="font-text fw-bold me-2">Contact Us </span>
                            </div>
                            <ul className="font-text footer-sec-first ">
                                <li className="mb-2 dis-none">
                                    <img src="image/location_cross_icon.svg" /> Cape Town, South Africa
                                </li>
                                <li className="mb-2">
                                    <img src="image/sms_tracking_icon.svg" />info@scholarpresent.com
                                </li>
                                <li className="mb-2">
                                    <img src="image/call_incoming_icon.svg" />+27810010019
                                </li>

                            </ul>

                        </div>
                    </div>

                </div>
            </div>
            {/* Footer Section End */}

            {/* Copyright Section Start */}
            <div className="copyright-section primary-bg">
                <div className="container">
                    <div className="row">
                        <div className="col text-center font-text copyright-text-section">
                            Copyright reserved © Scholar Present
                        </div>
                    </div>

                </div>
            </div>
            {/* Copyright Section End */}
        </div>
    );
}

export default Footer;
