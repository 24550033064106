import React from 'react';

function AboutUs() {
    return (
        <div>
            {/* Qualities Section Start */}
            <div className="aboutus-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-sm-6  col-12">
                            <p className="primary-text opacity-6 about-section-text pt-3">
                                Accounted for, at hand, available, here, in attendance, nearby, ready...
                            </p>
                            <p className="primary-text opacity-6 about-section-text pt-3">
                                These are all describing words of being present and represent 
                                what Scholar Present aims to enable scholars, parents, educators and the
                                community to do on a single platform. Combined with our mark, the 
                                children who are at the centre. We have unique identity that 
                                represents the bases of our value.
                            </p>   
                            <p className="primary-text opacity-6 about-section-text pt-3">
                            Scholar Present aims to bring together communities across the world in
                            order to inspire, guide and nurture
                            children. We want to ensure this is achieved through modern
                            technological mediums and set new standards in
                            proving a safe and nurturing platform to enable inspired connections.


                            </p>                         
                            

                        </div>
                        <div className="col col-sm-6  col-12 text-center text-sm-left">
                            <img src="image/scholarpresent.png" alt="" className="d-inline-block align-text-top wd-100" />
                        </div>

                    </div>
                </div>

                <div className="connection-section secondarylight-bg custom-section d-sm-none d-block extra-section">
                    <div className="container">
                        <div className="row">
                            <div className="col d-none d-sm-block">
                                <span className="primary-text fw-bold custom-heading">Creating Inspired</span>
                                <span className="secondary-text fw-bold custom-heading"> Connections</span>
                            </div>
                            <div className="col d-block d-sm-none">
                                <span className="primary-text fw-bold custom-heading">Key features of</span>
                                <span className="secondary-text fw-bold custom-heading">  Scholar Present </span>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-3 g-3 pt-md-5 pt-0 mobile-section align-items-center">
                            <div className="col">
                                <div className="card h-100 border-0 bg-transparent">
                                    <img src="image/working_icon.svg" className="card-img-top w-60" alt="..." />
                                    <div className="card-body ps-0 pt-4">
                                        <h5 className="card-title primary-text fw-bold  custom-sub-heading">Connect</h5>
                                        <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                            <li>
                                                Scholar Present is built around creating easy to
                                            </li>
                                            <li>
                                                manage connections between schools and parents
                                            </li>
                                            <li>
                                                to actively participate in scholars progress.Connect
                                            </li>
                                            <li>
                                                on one user friendly platform.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card h-100 border-0 bg-transparent">
                                    <img src="image/meeting_icon.svg" className="card-img-top w-60" alt="..." />
                                    <div className="card-body ps-0 pt-4">
                                        <h5 className="card-title primary-text fw-bold custom-sub-heading">Communicate</h5>
                                        <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                            <li>
                                                Innovative tools on Scholar Present make
                                            </li>
                                            <li>
                                                communicating a one-touch function.
                                            </li>
                                            <li>
                                                Communicate with all participants no matter what
                                            </li>
                                            <li>
                                                device they are using.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card h-100 border-0 bg-transparent">
                                    <img src="image/collaboration_icon.svg" className="card-img-top w-60" alt="..." />
                                    <div className="card-body ps-0 pt-4">
                                        <h5 className="card-title primary-text fw-bold custom-sub-heading">Collaborate</h5>
                                        <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                            <li>
                                                Direct messaging, group discussions, shared
                                            </li>
                                            <li>
                                                calendar, document sharing...Scholar Present
                                            </li>
                                            <li>
                                                provides an intuitive platform for participants to
                                            </li>
                                            <li>
                                                create meaningful collaborations
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
            {/* Qualities Section End */}
        </div>
    );
}

export default AboutUs;
