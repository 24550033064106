import React from 'react';
import Home from '../Home';
import ContactUs from '../ContactUs';
import AboutUs from '../AboutUs';
import PrivacyPolicy from '../PrivacyPolicy';
import DownloadApp from '../DownloadApp';
import SignUp from '../SignUp';
import Footer from '../Common/Footer'
import Faq from '../Faq';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, useHistory
} from "react-router-dom"
function Header() {
    const history = useHistory();

    return (
        <div>
            <Router>
                <div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white p-1 navbar-section">
                        <div className="container">
                            <a className="navbar-brand image-section" href="#">
                                <img src="image/logo.png" alt="" className="d-inline-block align-text-top" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav ms-lg-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link active fw-600 primary-text px-3 custom-dropdown-item" aria-current="page" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link fw-600 primary-text px-3 custom-dropdown-item" to="/app">Download App</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link fw-600 primary-text px-3 custom-dropdown-item" to="/aboutus">About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link fw-600 primary-text px-3 custom-dropdown-item" to="/faq">FAQs</Link>
                                    </li>
                                    <li className="nav-item pe-5">
                                        <Link className="nav-link fw-600 primary-text px-3 custom-dropdown-item" to="/contactus">Contact Us</Link>
                                    </li>

                                </ul>
                                <Link className="nav-link primary-text pe-5 secondary-text sin-up-btn nav-button-device" to="" onClick={event =>  window.location.href='https://platform.scholarpresent.com/login'}>Sign In</Link>
                                {/* <a className="nav-link fw-600  reg-btn nav-button-device " href="#">Register</a> */}
                                <div className="dropdown nav-button-device-dropdown">
                                    <button className="btn btn-secondary dropdown-toggle nav-button-device   reg-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Register
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="https://platform.scholarpresent.com/joinschool">Join My School</a></li>
                                    <li><a className="dropdown-item" href="https://platform.scholarpresent.com/newschool">New School</a></li>
                                    </ul>
                                </div>

                                {/* mobile view */}
                                {/* <a className="nav-link fw-600  reg-btn nav-button-mobile-device" href="#">Register</a> */}
                                <div className="dropdown nav-button-device-dropdown">
                                    <button className="btn btn-secondary dropdown-toggle reg-btn nav-button-mobile-device  " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Register
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                     <li><a className="dropdown-item " href="https://platform.scholarpresent.com/joinschool">Join My School</a></li>
                                    <li><a className="dropdown-item" href="https://platform.scholarpresent.com/newschool">New School</a></li>
                                    </ul>
                                </div>
                                <a className="nav-link primary-text pe-5 secondary-text sin-up-btn nav-button-mobile-device" href="https://platform.scholarpresent.com/login">Sign In</a>
                                {/* <button className="btn btn-success  secondary-btn fs-12 px-4 reg-btn" type="submit">Register</button> */}
                            </div>
                        </div>
                    </nav>
                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Switch>
                        <Route path="/aboutus">
                            <AboutUs />
                        </Route>
                        <Route path="/faq">
                            <Faq />
                        </Route>
                        <Route path="/contactus">
                            <ContactUs />
                        </Route>
                        <Route path="/app">
                            <DownloadApp />
                        </Route>
                        <Route path="/signup">
                            <SignUp />
                        </Route>
                        <Route path="/privacypolicy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </Router>

        </div>
    );
}

export default Header;
