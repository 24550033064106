import React from 'react';

function ContactUs() {
    return (
        <div>
            {/* Slider Section start */}
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">

                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <img src="#" className="d-block w-100 contact-image" alt="..." />
                    </div>


                </div>


            </div>
            {/* Slider Section End */}
            {/* Contact form Section Start */}
            <div className="contactus-section">

                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div className="col col-sm-6 col-12 border-ra-10 ">
                            <div className="">
                                <h2 className="primary-text fw-bold">Do you have questions?</h2>
                                <h2 className="primary-text fw-bold"> Reach out to us!</h2>
                            </div>
                            <div className="contactus-address-section">
                                <ul>
                                    {/* <li>
                                        <img src="image/phone_icon.svg" alt="" />
                                        <div>
                                            <p className="address-heading">Phone</p>
                                            <p className="address-text">201-880-4873</p>
                                        </div>
                                    </li> */}
                                    <li>
                                        <img src="image/email_icon.svg" alt="" />
                                        <div>
                                            <p className="address-heading">Email Address</p>
                                            <p className="address-text"><a style={{ textDecoration: 'none', color: "#000000" }} href="mailto:info@scholarpresent.com">info@scholarpresent.com</a></p>
                                        </div>
                                    </li>
                                    <li className="dis-none">
                                        <img src="image/loaction_icon.svg" alt="" />
                                        <div>
                                            <p className="address-heading">Location</p>
                                            <p className="address-text">240 W.Passaic St., Suite 12 Maywood, NJ 07607</p>
                                        </div>
                                    </li>
                                    <li className="dis-none">
                                        <img src="image/office_icon.svg" alt="" />
                                        <div>
                                            <p className="address-heading">Office Hours</p>
                                            <p className="address-text">Monday - Friday: 8AM–7PM </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col col-sm-6 col-12  border-ra-10 contact-form-box">
                            <div className="contact-form-section">
                                <div className="row">
                                    <div className="col-md-12 contact-col">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Your Name*</label>
                                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter your name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 contact-col">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Email address*</label>
                                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter email address" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 contact-col">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Contact Number</label>
                                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter your contact number" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 contact-col">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Message</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} placeholder="Enter your message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-grid gap-2">
                                        <button className="btn btn-success custom-btn-submit" type="submit">Submit Now</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>

            </div>
            {/* Contact form Section End */}

        </div>
    );
}

export default ContactUs;
