import React from 'react';
import {
    Link
} from "react-router-dom"
function SignUp() {
    return (
        <div>
            {/* Download Section Start */}
            <div className="signup-popup-section">
                <div className="container">
                    <div className="row custom-row">
                        <div className="col col-lg-6  connect-section">
                            <p className="connect-heading primary-text">
                                Connect Now
                            </p>
                            <p className="mb-0 secondary-text pt-3">
                                Communication and Collaboration
                            </p>
                            <p className="mb-0 primary-text opacity-6  pt-2">
                                Learning institutions are important contributors in all communities and children will always remember school as the first place they made a real connection to the community they live in.
                            </p>
                            <div className="row signup-image-section">
                                <div className="col col-lg-6">
                                    <a style={{ textDecoration: 'none' }} href='https://platform.scholarpresent.com/joinschool'>

                                    <div className="card h-100 border-ra-5 border-secondary signup-image-box border-2">
                                        <img src="image/new-user_icon.svg" className="card-img-top h-100" alt="..." />
                                        <div className="card-body p-0">
                                            <h5 className="card-title secondary-text fw-bold fs-13 text-center pt-2">Join My School</h5>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col col-lg-6">
                                <a style={{ textDecoration: 'none' }} href="https://platform.scholarpresent.com/newschool">
                                    <div className="card h-100 border-ra-5 border-secondary signup-image-box border-2">
                                        <img src="image/new_school_icon.svg" className="card-img-top h-100" alt="..." />
                                        <div className="card-body p-0">
                                            <h5 className="card-title secondary-text fw-bold fs-13 text-center pt-2">New School</h5>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>

                            <div className="row pt-5">
                                <div className="col">
                                    <span className="primary-text opacity-6 me-2">
                                        Already have an account?
                                    </span>
                                    <span className="secondary-text">
                                    <a style={{ textDecoration: 'none', color: "#299F54" }} href="https://platform.scholarpresent.com/login">Sign In</a>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className="col col-lg-6 px-0 connect-image">
                            <img src="image/downloading-image.png" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Community Section End */}
        </div>
    );
}

export default SignUp;
