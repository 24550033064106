import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, Analytics } from 'aws-amplify';

import Home from './Home';
import Header from './Common/Header';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

Analytics.autoTrack('session', {
  enable: true
});

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA'
});

Analytics.autoTrack('event', {
  enable: true
});

ReactDOM.render(
  <React.StrictMode>
    <Header />

  </React.StrictMode>,
  document.getElementById('root')
);

