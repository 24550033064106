import React from 'react';

function DownloadApp() {
    return (
        <div>
            {/* Qualities Section Start */}
            <div className="downloadapp-section">

                <div className="container downlaod-section">
                    <div className="row justify-content-center g-5  p-relative">
                        <div className="col col-lg-10 primary-bg border-ra-10 ">
                            <div className="custom-download-app">
                                <h2 className="secondary-text fw-bold">DISCOVER SCHOLAR PRESENT</h2>
                                <h2 className="secondary-text fw-bold">DOWNLOAD THE APP</h2>
                                <a href='https://play.google.com/store/apps/details?id=iconnect99.message.scholarpresent'>
                                    <img className="btn-download-app" src="image/android_icon_button.svg" alt="..." />
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;

                                <a href='https://apps.apple.com/app/scholar-present/id1599918674'>
                                    <img className="btn-download-app me-3" src="image/ios_icon_button.svg" alt="..." />
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;

                                <a href='https://appgallery.huawei.com/app/C106350359'>
                                    <img className="btn-download-app me-3" src="image/huawei.png" alt="..." />
                                </a>
                                <br/>
                                <br/>
                                <a style={{color: 'White' }} href='https://platform.scholarpresent.com/joinschool'>
                                    <b>Access using a browser</b>
                                </a>

                            </div>
                            <div className="download-app-mobile">
                                <img className="btn-download-app me-3" src="image/iphone8_icon.svg" alt="..." />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {/* Qualities Section End */}
        </div>

    );
}

export default DownloadApp;
