import { useEffect, useState, useRef, forwardRef } from "react";
import { Amplify, Analytics } from 'aws-amplify';

import {
 useHistory
} from "react-router-dom"

function Home() {
    const history = useHistory();
    
    useEffect(() => {
        
    }, []);

    

    return (
        <div>
            <h1 style={{
            position: "absolute", 
            clip: "rect(0 0 0 0)", 
            clipPath: "inset(50%)", 
            height: "1px", 
            overflow: "hidden", 
            whiteSpace: "nowrap", 
            width: "1px"
            }}>
      Enhancing quality of school through connection, communication and collaboration.</h1>

            {/* Slider Section start*/}
            <div id="carouselExampleCaptions" className="carousel slide custom-slide-carousel p-relative" data-bs-ride="carousel">
           
                <div className="carousel-inner custom-carousel">
                    <div className="carousel-item active">
                        <div className="overlap-text">
                            <h2>
                            <p>
                                Our Vision Is To Drive Academic
                            </p>
                            <p>
                                Performance Through Communication
                            </p>
                            <p>
                                And Collaborations
                            </p>
                            </h2>
                            <div className="pt-md-5 pt-sm-1">
                                <button className="btn btn-success  xsecondary-btn me-3" type="button" 
                                data-amplify-analytics-name='app-access'
                                data-amplify-analytics-attrs={ 'action:sign-up' }
                                onClick={event =>  history.push("/signup")}>Sign Up</button>
                                <button className="btn btn-success  xsecondary-btn " type="button" 
                                data-amplify-analytics-name='app-access'
                                data-amplify-analytics-attrs={ 'action:download-app-page' }
                                onClick={event =>  history.push("/app")}>Download App</button>
                            </div>
                        </div>
                        <div className="overlap-bg">
                        </div>
                        <img src="image/slider1.png" className="d-block w-100" alt="..." />

                    </div>
                    <div className="carousel-item">
                        <div className="overlap-text">
                            <p>
                                Bringing School Communities
                            </p>
                            <p>
                                Together Through Effective
                            </p>
                            <p>
                                Communication
                            </p>

                            <div className="pt-md-5 pt-sm-1">
                                <button className="btn btn-success  xsecondary-btn me-3"
                                data-amplify-analytics-name='app-access'
                                data-amplify-analytics-attrs={ 'action:sign-up' }
                                type="submit">Sign Up</button>
                                <button className="btn btn-success  xsecondary-btn  px-4" 
                                data-amplify-analytics-name='app-access'
                                data-amplify-analytics-attrs={ 'action:download-app-page' }
                                type="submit">Download App</button>
                            </div>
                        </div>
                        <div className="overlap-bg">
                        </div>
                        <img src="image/slider2.png" className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <div className="overlap-text">
                            <p>
                                Every Child Needs A Present
                            </p>
                            <p>
                                Parent To Support Their
                            </p>
                            <p>
                                Learning Journey
                            </p>

                            <div className="pt-md-5 pt-sm-1">
                                <button className="btn btn-success  xsecondary-btn me-3" type="submit">Sign Up</button>
                                <button className="btn btn-success  xsecondary-btn " type="submit">Download App</button>
                            </div>
                        </div>
                        <div className="overlap-bg">
                        </div>
                        <img src="image/slider3.png" className="d-block w-100" alt="..." />
                    </div>

                </div>
                <div className="carousel-indicators custom-carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
                <button className="carousel-control-prev custom-carousel-indicators-left" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next custom-carousel-indicators-right" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>

            </div>
            {/* Slider Section End*/}

            {/* Community Section Start*/}
            <div className="community-section custom-section">
                <div className="container">
                    <div className="row">
                        <div className="col d-none d-sm-block">
                            <span className="primary-text fw-bold custom-heading">Scholar Present</span>
                            <span className="secondary-text fw-bold custom-heading"> Community</span>
                        </div>
                        <div className="col d-block d-sm-none">
                            <span className="primary-text fw-bold custom-heading">How </span>
                            <span className="secondary-text fw-bold custom-heading">Scholar Present</span>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4 pt-md-5 pt-0 mobile-section">
                        <div className="col ">
                            <div className="card h-100 border-0">
                                <img src="image/school_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold custom-sub-heading">School </h5>
                                    <ul className="primary-text opacity-6  custom-liststyle custom-text">
                                        <li>
                                            Scholar Present is an easy to use platform that
                                        </li>
                                        <li>
                                            creates the capacity for educators to focus on
                                        </li>
                                        <li>
                                            playing important
                                        </li>
                                        <li>
                                            roles in schools by taking away communication
                                        </li>
                                        <li>
                                            barriers and promoting collaborations with parents and scholars.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="image/family_svg.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold  custom-sub-heading">Parents </h5>
                                    <ul className="primary-text opacity-6  custom-liststyle custom-text">
                                        <li>
                                            As a scholar you will remember saying “present”
                                        </li>
                                        <li>
                                            when your teacher called your name at the start of
                                        </li>
                                        <li>
                                            each school day. Scholar Present is the app to
                                        </li>
                                        <li>
                                            connect parents to schools and keeps you present in
                                        </li>
                                        <li>
                                            children’s schooling.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="image/learning_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold custom-sub-heading">Scholar</h5>
                                    <ul className="primary-text opacity-6  custom-liststyle custom-text">
                                        <li>
                                            School is meant to be a place of learning, growth and
                                        </li>
                                        <li>
                                            connecting with likeminded individuals, while
                                        </li>
                                        <li>
                                            learning from different perspectives. Discover what
                                        </li>
                                        <li>
                                            you are good at, what you enjoy and let Scholar
                                        </li>
                                        <li>
                                            Present take care of the rest.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Community Section End*/}


            {/* Connections Section Start*/}
            <div className="connection-section secondarylight-bg custom-section">
                <div className="container">
                    <div className="row">
                        <div className="col d-none d-sm-block">
                            <h2>
                            <span className="primary-text fw-bold custom-heading">Creating Inspired</span>
                            <span className="secondary-text fw-bold custom-heading"> Connections</span>
                            </h2>
                        </div>
                        <div className="col d-block d-sm-none">
                            <span className="primary-text fw-bold custom-heading">Key features of</span>
                            <span className="secondary-text fw-bold custom-heading">  Scholar Present </span>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3 pt-md-5 pt-0 mobile-section align-items-center">
                        <div className="col">
                            <div className="card h-100 border-0 bg-transparent">
                                <img src="image/working_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold  custom-sub-heading">Connect</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                        <li>
                                            Scholar Present is built around creating easy to
                                        </li>
                                        <li>
                                            manage connections between schools and parents
                                        </li>
                                        <li>
                                            to actively participate in scholars progress. Connect
                                        </li>
                                        <li>
                                            on one user friendly platform.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0 bg-transparent">
                                <img src="image/meeting_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold custom-sub-heading">Communicate</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                        <li>
                                            Innovative tools on Scholar Present make
                                        </li>
                                        <li>
                                            communicating a one-touch function.
                                        </li>
                                        <li>
                                            Communicate with all participants no matter what
                                        </li>
                                        <li>
                                            device they are using.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0 bg-transparent">
                                <img src="image/collaboration_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title primary-text fw-bold custom-sub-heading">Collaborate</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">
                                        <li>
                                            Direct messaging, group discussions, shared
                                        </li>
                                        <li>
                                            calendar, document sharing...Scholar Present
                                        </li>
                                        <li>
                                            provides an intuitive platform for participants to
                                        </li>
                                        <li>
                                            create meaningful collaborations
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="signup-popup-section home-signup-popup-section">
                <div className="container">
                    <div className="row custom-row">
                        <div className="col col-lg-6  connect-section">
                            <p className="connect-heading primary-text">
                                Connect Now
                            </p>
                            <p className="mb-0 secondary-text pt-3">
                                Communication and Collaboration
                            </p>
                            <p className="mb-0 primary-text opacity-6  pt-2">
                                Learning institutions are important contributors in all communities and children will always remember school as the first place they made a real connection to the community they live in.
                            </p>
                            <div className="row signup-image-section home-image-section">
                                <div className="col col-lg-6">
                                        <a style={{ textDecoration: 'none' }} href='https://platform.scholarpresent.com/joinschool'>

                                            <div className="card h-100 border-ra-5 border-secondary signup-image-box border-2">
                                                <img src="image/new-user_icon.svg" className="card-img-top h-100" alt="..." />
                                                <div className="card-body p-0">
                                                    <h5 className="card-title secondary-text fw-bold fs-13 text-center pt-2">Join My School</h5>
                                                </div>
                                            </div>
                                        </a>
                                </div>
                                <div className="col col-lg-6">
                                    <a style={{ textDecoration: 'none' }} href="https://platform.scholarpresent.com/newschool">
                                    <div className="card h-100 border-ra-5 border-secondary signup-image-box border-2">
                                        <img src="image/new_school_icon.svg" className="card-img-top h-100" alt="..." />
                                        <div className="card-body p-0">
                                            <h5 className="card-title secondary-text fw-bold fs-13 text-center pt-2">New School</h5>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>

                            <div className="row pt-5">
                                <div className="col">
                                    <span className="primary-text opacity-6 me-2">
                                        Already have an account?
                                    </span>
                                    <span className="secondary-text">
                                        <a style={{ textDecoration: 'none', color: "#299F54" }} 
                                        data-amplify-analytics-on='click'
                                        data-amplify-analytics-name='app-access'
                                        data-amplify-analytics-attrs={ 'action:sign-in ' }
                                        href="https://platform.scholarpresent.com/login">Sign In</a>

                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className="col col-lg-6 px-0 connect-image">
                            <img src="image/downloading-image.png" />
                        </div>
                    </div>
                </div>
            </div>

                <div className="container discover-section">
                    <div className="row">
                        <div className="col d-none d-sm-block">
                            <span className="custom-heading primary-text fw-bold">Discover</span> &nbsp;
                            <span className="custom-heading secondary-text fw-bold">Scholar Present</span>

                        </div>
                        <div className="col d-block d-sm-none">
                            <span className="custom-heading primary-text fw-bold ">Choose to be </span>
                            <span className="custom-heading secondary-text fw-bold ">PRESENT</span>
                        </div>
                    </div>
                    <div className="row  g-3 pt-md-5 pt-0">
                        <div className="col col-12 col-sm-4">
                            <div className="card h-100 border-ra-0 border-secondary custom-card-section border-2">
                                <img src="image/collaboration_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">School Contact List</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            Use our intuitive upload contact list
                                        </li>
                                        <li>
                                            template to create your contacts list in one
                                        </li>
                                        <li>
                                            easy step. All you need is a contact name to
                                        </li>
                                        <li>
                                            get started.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-4">
                            <div className="card h-100 border-ra-0 border-secondary custom-card-section border-2">
                                <img src="image/support-services_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">Unified Contact Management</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            User friendly contact management that
                                        </li>
                                        <li>
                                            automatically organizes uploaded contact
                                        </li>
                                        <li>
                                            groups for easy communication.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-4 mb-3 mb-sm-0">
                            <div className="card h-100 border-ra-0 border-secondary custom-card-section border-2">
                                <img src="image/file_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">Document Management</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            Save printing costs and time in
                                        </li>
                                        <li>
                                            communicating school notices and
                                        </li>
                                        <li>
                                            announcements. Manage all important
                                        </li>
                                        <li>
                                            documents and polices on one platform.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3 pt-md-3">
                        <div className="col col-12 col-sm-4">
                            <div className="card h-100 border-secondary border-ra-0 custom-card-section border-2">
                                <img src="image/download_icon.svg" className="card-img-top w-60 pb-2" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">Free to Download</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            Parents and learners will never again miss
                                        </li>
                                        <li>
                                            activities and meetings. RSVP and get
                                        </li>
                                        <li>
                                            reminders closer to event date.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-4">
                            <div className="card h-100 border-secondary border-ra-0 custom-card-section border-2">
                                <img src="image/interactive_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">Multi Channel</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            Scholar Present connects to all users no
                                        </li>
                                        <li>
                                            matter what device they use or how they
                                        </li>
                                        <li>
                                            want to be communicated with.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-4">
                            <div className="card h-100 border-secondary border-ra-0 custom-card-section border-2">
                                <img src="image/chart_icon.svg" className="card-img-top w-60" alt="..." />
                                <div className="card-body ps-0 pt-4">
                                    <h5 className="card-title secondary-text fw-bold custom-subinner-heading">PRESENT Analytics</h5>
                                    <ul className="primary-text opacity-6 custom-text custom-liststyle">

                                        <li>
                                            Get a view on your school’s activity on
                                        </li>
                                        <li>
                                            Scholar Present to ensure you are getting
                                        </li>
                                        <li>
                                            the most out of the platform
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Connections Section End*/}

            {/* Qualities Section Start*/}
            <div className="qualities-section custom-section">
                <div style={{ display: "none" }} className="container">
                    <div className="row">
                        <div className="col text-sm-center text-left  ps-0 ps-sm-3">
                            <span className="custom-heading primary-text fw-bold ">Scholar Present </span>
                            <span className="custom-heading secondary-text fw-bold">Qualities</span>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-5 pt-md-5 pt-0 justify-content-center">
                        <div className="col col-lg-2  me-4 col-md-2 qualities-section-col">
                            <img src="image/secured.svg" className="w-90" alt="..." />
                            <p className="primary-text mb-0 fw-bold pt-3 custom-text">Secured</p>
                        </div>
                        <div className="col col-lg-2 me-4 col-md-2 qualities-section-col">
                            <img src="image/Parents_get_instant.svg" className="w-90" alt="..." />
                            <p className="primary-text mb-0 fw-bold pt-3 custom-text">Parents get instant </p>
                        </div>
                        <div className="col col-lg-2 me-4 col-md-2 qualities-section-col">
                            <img src="image/Multiple_endpoints.svg" className="w-90" alt="..." />
                            <p className="primary-text mb-0 fw-bold pt-3 custom-text">Multiple endpoints</p>
                        </div>
                        <div className="col col-lg-2 me-4 col-md-2 qualities-section-col">
                            <img src="image/Support_email.svg" className="w-90" alt="..." />
                            <p className="primary-text mb-0 fw-bold pt-3 custom-text">Support emails, SMS </p>
                        </div>
                        <div className="col col-lg-2 me-4 col-md-2 qualities-section-col">
                            <img src="image/Build_cloud.svg" className="w-90" alt="..." />
                            <p className="primary-text mb-0 fw-bold pt-3 custom-text">Build on cloud</p>
                        </div>
                    </div>
                </div>
                <div className="container downlaod-section">
                    <div className="row justify-content-center g-5  p-relative">
                        <div className="col col-lg-10 primary-bg border-ra-10 ">
                            <div className="custom-download-app">
                                <h2 className="secondary-text fw-bold">DISCOVER SCHOLAR PRESENT</h2>
                                <h2 className="secondary-text fw-bold">DOWNLOAD THE APP</h2>
                                <a href='https://play.google.com/store/apps/details?id=iconnect99.message.scholarpresent'>
                                    <img className="btn-download-app" src="image/android_icon_button.svg" alt="..." />
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href='https://apps.apple.com/app/scholar-present/id1599918674'>
                                    <img className="btn-download-app me-3" src="image/ios_icon_button.svg" alt="..." />
                                </a>
                                <br/>
                                <br/>
                                <a style={{color: 'White' }} href='https://platform.scholarpresent.com/joinschool'>
                                    <b>Access using a browser</b>
                                </a>
                            </div>
                            <div className="download-app-mobile">
                                <img className="btn-download-app me-3" src="image/iphone8_icon.svg" alt="..." />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {/* Qualities Section End*/}



        </div>
    );
}

export default Home;
