import React from 'react';

function Faq() {
    return (
        <div>
            {/* Qualities Section Start */}
            <div className="aboutus-section">
                <div className="container feature-section">
                    <div className="row align-items-center">
                        <div className="col feature-heading-section">
                            <span className="primary-text fw-bold custom-heading">Frequently Asked Questions</span>
                        </div>

                    </div>
                    <div className="row justify-content-center feature-expand-section">
                        <div className="col col-md-10">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            Do I need data to use Scholar Present?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Scholar Present uses the phone's cellular connection or WI-FI network to send and receive messages to your fellow staff members, parents, and/or students. As long as you haven't exceeded your mobile data allowance or connected to a free WI-FI network, your internet service provider shouldn't charge you for messaging or any downloads of multimedia.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            How do I use Scholar Present on my computer?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Scholar Present can be accessed at <a style={{ textDecoration: 'none', color: "#299F54" }} href="https://platform.scholarpresent.com">https://platform.scholarpresent.com</a> and log in using your cellphone number as the username. You will have to enter the OTP sent to the mobile number for the authentication process.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            How do I restore my information if I change my phone number?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">To change your cellphone number and migrate the profile to the new number, log in using the current number, navigate to the profile, and change the number. follow the detailed steps to update your profile number.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            Does Scholar Present support older phones?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Scholar Present can be installed on any device running Android 4.1 or later. iPhones that are running on iOS 8 or older will not support Scholar Present.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            How do I get my school on to Scholar Present?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Got enroll your school, visit <a style={{ textDecoration: 'none', color: "#299F54" }} href="https://platform.scholarpresent.com/newschool">https://platform.scholarpresent.com/newschool</a> and follow the steps from there.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed align-items-baseline" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                            Does my child have to be on Scholar Present for me to sign up?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body"> Yes, you are required to be linked to your learner in order to get relevant updates related to your child. As a parent or guardian, you have access to your child's Scholar Present profile. </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* Qualities Section End */}
        </div>

    );
}

export default Faq;
