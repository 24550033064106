/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// MASTER ENVIRONMENT CHECK aws_mobile_analytics_app_id MUST BE CHANGED
// const awsmobile = {
//     "aws_project_region": "eu-central-1",
//     "aws_cognito_identity_pool_id": "eu-central-1:0b0577e1-6ece-46c6-8845-1b4d01031d4e",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_QUQc5NruH",
//     "aws_user_pools_web_client_id": "7co58cc2bsb1bi4s3vmr0fnik2",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://e34tmwjuprd5xb6vaaekxhjs5y.appsync-api.eu-central-1.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-central-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    
//     //MUST CHANGE IT MIGHT POINTING TO DEV
//     "aws_mobile_analytics_app_id": "f9c02996bbe04141b887a25b37a0893c",
//     "aws_mobile_analytics_app_region": "eu-central-1",
//      "version": "1.6.13",
//     // END
//     "aws_user_files_s3_bucket": "iconnect99backend89ce0a116bdc473d9f1e27bba0f2e150940-master",
//     "aws_user_files_s3_bucket_region": "eu-central-1"
// };

// QA environment
// const awsmobile:any = {
// "aws_project_region": "eu-central-1",
//     "aws_cognito_identity_pool_id": "eu-central-1:1d195de2-6b5f-4b7d-be55-7b185d1adac8",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_UA8SmyBGP",
//     "aws_user_pools_web_client_id": "jove7g5fmet8l19qtugincjco",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://v2a7bwvh6zcdvbau75czdb3obm.appsync-api.eu-central-1.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-central-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_mobile_analytics_app_id": "f9c02996bbe04141b887a25b37a0893c",
//     "aws_mobile_analytics_app_region": "eu-central-1",
//     "aws_user_files_s3_bucket": "iconnect99backend89ce0a116bdc473d9f1e27bba0f2e1151652-qa",
//     "aws_user_files_s3_bucket_region": "eu-central-1",
//       "version": "1.6.13"

// };
//DEV environment
// Old Dev environment
// const awsmobile:any = {
//   "aws_project_region": "eu-central-1",
//   "aws_cognito_identity_pool_id": "eu-central-1:fe4d9992-855f-4e9a-9014-17ad602ddb2a",
//   "aws_cognito_region": "eu-central-1",
//   "aws_user_pools_id": "eu-central-1_7tYh1k6nV",
//   "aws_user_pools_web_client_id": "1s1hj8cgu9ohpruta766509flb",
//   "oauth": {},
//   "aws_appsync_graphqlEndpoint": "https://4c2gj6tkbzbsramffwkb6im7ru.appsync-api.eu-central-1.amazonaws.com/graphql",
//   "aws_appsync_region": "eu-central-1",
//   "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//   "aws_user_files_s3_bucket": "iconnect99backend89ce0a116bdc473d9f1e27bba0f2e144247-dev",
//   "aws_user_files_s3_bucket_region": "eu-central-1",
//   "aws_mobile_analytics_app_id": "f9c02996bbe04141b887a25b37a0893c",
//   "aws_mobile_analytics_app_region": "eu-central-1",
//   "version": "1.6.13"
// };

const awsmobile:any = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:fe4d9992-855f-4e9a-9014-17ad602ddb2a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_7tYh1k6nV",
    "aws_user_pools_web_client_id": "1s1hj8cgu9ohpruta766509flb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER, EMAIL"
    ],
    // "aws_appsync_graphqlEndpoint": "https://4c2gj6tkbzbsramffwkb6im7ru.appsync-api.eu-central-1.amazonaws.com/graphql",
    // "aws_appsync_region": "eu-central-1",
    // "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    // "aws_user_files_s3_bucket": "iconnect99backend89ce0a116bdc473d9f1e27bba0f2e144247-dev",
    // "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "c7c8402b37bd469cb88cf32654729020",
    "aws_mobile_analytics_app_region": "eu-central-1"
};

export default awsmobile;
